
import React from "react"
import { Row, Col } from "react-bootstrap"
import { SocialIcon } from 'react-social-icons';
class SocialShare extends React.Component {
  constructor(props) {
    super(props);
  }

  render(){
    var postTitle = this.props.postTitle;
    var postUrl = this.props.postUrl;
    var postImage = this.props.postImage;
    return (
      <React.Fragment>
        <span className="social-share-span">
        <a href={"https://api.whatsapp.com/send?text="+postTitle+" "+postUrl}
          target="_blank">
          <SocialIcon
            style={{ height: 30, width: 30, zIndex: `-10` }}

            network="whatsapp"
            url={"https://api.whatsapp.com/send?text="+postTitle+" "+postUrl}
          />
        </a>
        </span>
        <span className="social-share-span">
        <a href={"https://www.facebook.com/sharer.php?u="+postUrl}
          target="_blank">
          <SocialIcon
            style={{ height: 30, width: 30, zIndex: `-10` }}

            network="facebook"
          />
        </a>
        </span>
        <span className="social-share-span">
        <a href={"https://twitter.com/share?url="+postUrl+"&text="+postTitle+"&hashtags=brrrlabs"}
          target="_blank">
          <SocialIcon
            style={{ height: 30, width: 30, zIndex: `-10` }}

            network="twitter"
          />
        </a>
        </span>
        <span className="social-share-span">
        <a href={"https://pinterest.com/pin/create/bookmarklet/?media="+postImage+"&url="+postUrl+"&is_video=false&description="+postTitle}
          target="_blank">
          <SocialIcon
            style={{ height: 30, width: 30, zIndex: `-10` }}

            network="pinterest"
          />
        </a>
        </span>
        <span className="social-share-span">
        <a href={"https://www.linkedin.com/shareArticle?url="+postUrl+"&title="+postTitle}
          target="_blank">
          <SocialIcon
            style={{ height: 30, width: 30, zIndex: `-10` }}

            network="linkedin"
          />
        </a>
        </span>
        <span className="social-share-span">
        <a href={"https://reddit.com/submit?url="+postUrl+"&title="+postTitle}
          target="_blank">
          <SocialIcon
            style={{ height: 30, width: 30, zIndex: `-10` }}

            network="reddit"
          />
        </a>
        </span>
        <span className="social-share-span">
        <a href={"https://t.me/share/url?url="+postUrl+"&text="+postTitle}
          target="_blank">
          <SocialIcon
            style={{ height: 30, width: 30, zIndex: `-10` }}

            network="telegram"
          />
        </a>
        </span>
      </React.Fragment>
    )
  }
}

export default SocialShare
