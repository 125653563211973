
import React from "react"
import { Row, Col } from "react-bootstrap"
import SocialShare from "./socialShare"

class PostHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  render(){
    const isMobile = this.props.isMobile;
    if (isMobile) {
      return(
        <Row className={"post-header-mobile mx-auto"}>
          <Col md={6} sm={6} lg={6} xl={6} xs={12} >
            <small>{this.props.data.postTitle}</small>
            <br></br>
            <small>{this.props.data.year}</small><span><small className="post-category">{" " + this.props.data.category}</small></span>
            <hr></hr>
          </Col>
          <Col md={6} sm={6} lg={6} xl={6} xs={12} >
            <div dangerouslySetInnerHTML={{__html: this.props.data.postDescription.childMarkdownRemark.html}} />
            <hr></hr>
            <SocialShare
              postTitle={this.props.data.postTitle}
              postUrl={ encodeURI(encodeURI("https://brrrlabs.com/project/" + this.props.data.postTitle)) }
              postImage={this.props.data.featuredImage.file.url}
            />
          </Col>
        </Row>
      )
    }
    return(
      <Row className={this.props.show + " post-header mx-auto"}>
        <Col md={6} sm={6} lg={6} xl={6} xs={12} >
          <a href="/"><h5 className="post-category">⟵ Back Home</h5></a>
          <h5>{this.props.data.postTitle}</h5>
          <h5 className="post-year">{this.props.data.year}</h5>
          <h5 className="post-category">&nbsp;&nbsp;{this.props.data.category}</h5>
        </Col>
        <Col md={6} sm={6} lg={6} xl={6} xs={12} >
          <div dangerouslySetInnerHTML={{__html: this.props.data.postDescription.childMarkdownRemark.html}} />
          <SocialShare
            postTitle={this.props.data.postTitle}
            postUrl={ encodeURI(encodeURI("https://brrrlabs.com/project/" + this.props.data.postTitle)) }
            postImage={this.props.data.featuredImage.file.url}
          />
        </Col>
      </Row>
    )
  }
}

export default PostHeader
