
import React from "react"
import { Row, Col } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"

class PostGrid extends React.Component {
  constructor(props) {
    super(props);
  }

  render(){
    const isMobile = this.props.isMobile;
    var arr=this.props.data.postImages;
    var featuredPostList = arr.map(function(imgdata, index){
      if ((index+1) % 3 == 0) {
        if (isMobile) {
          return(
            <Col md={12} sm={12} lg={12} xl={12} xs={12} className="post-col">
              <GatsbyImage image={imgdata.gatsbyImageData} className="post-img" style={{height:`80vh`}}/>
            </Col>
          )
        } else {
          return(
            <Col md={12} sm={12} lg={12} xl={12} xs={12} className="post-col">
              <GatsbyImage image={imgdata.gatsbyImageData} imgStyle={{objectFit:`cover`}} className="post-img"/>
            </Col>
          )
        }

      } else {
        if (isMobile) {
          return(
            <Col md={6} sm={6} lg={6} xl={6} xs={12} className="post-col">
              <GatsbyImage image={imgdata.gatsbyImageData} className="post-img" style={{height:`80vh`}}/>
            </Col>
          )
        } else {
          return(
            <Col md={6} sm={6} lg={6} xl={6} xs={12} className="post-col">
              <GatsbyImage image={imgdata.gatsbyImageData} className="post-img"/>
            </Col>
          )
        }

      }
    })

    return(
      <Row className="post mx-auto">
      <Row className="mx-auto h-100">
        <Col md={12} sm={12} lg={12} xl={12} xs={12} className="post-col">
          <GatsbyImage image={this.props.data.featuredImage.gatsbyImageData} className="post-img"/>
        </Col>
        {featuredPostList}
      </Row>
      </Row>
    )
  }
}

export default PostGrid
