import React from "react"
import Layout from "../components/layout"
import Footer from "../components/footer"
import PostHeader from "../components/postHeader"
import PostGrid from "../components/postgrid"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import {isMobile} from 'react-device-detect';

class Post extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTop: true,
      y: 0,
      isUp: false,
      isMobile: false,
    };
  }

  componentDidMount() {
    this.setState({ isMobile: isMobile })
    document.addEventListener('scroll', () => {
      if (this.state.y < window.scrollY ) {
        this.setState({ isUp: false })
      } else {
        this.setState({ isUp: true })
      }
      this.setState({ y: window.scrollY })
    });
  }

  render() {
    return (
      <Layout pageInfo={{ pageName: this.props.data.allContentfulPost.nodes[0].postTitle }}>
        <SEO
          title={this.props.data.allContentfulPost.nodes[0].postTitle} keywords={['Brrrlabs', 'portfolio']}
          ogUrl={"/project/" + this.props.data.allContentfulPost.nodes[0].postTitle}
          description={this.props.data.allContentfulPost.nodes[0].postDescription.postDescription}
          ogImage={this.props.data.allContentfulPost.nodes[0].featuredImage.gatsbyImageData.images.sources[0].srcSet.split(",")[0].split(" ")[0]}
        />
        {!this.state.isMobile &&
            <PostHeader show={this.state.isUp ? 'showing' : 'not-showing'} data={this.props.data.allContentfulPost.nodes[0]} isMobile={this.state.isMobile}/>
        }
        {this.state.isMobile &&
            <PostHeader data={this.props.data.allContentfulPost.nodes[0]} isMobile={this.state.isMobile}/>
        }
        <PostGrid
          data={this.props.data.allContentfulPost.nodes[0]}
          isMobile={this.state.isMobile}
        />
        <Footer
          isMobile={this.state.isMobile}
          menus={this.props.data.allContentfulMenu.edges}
          siteInfo={this.props.data.allContentfulSiteInfo.nodes[0]}
          socials={this.props.data.allContentfulSocials.nodes}
        />
      </Layout>
    )
  }
}

export default Post

export const Query = graphql`
query Post($postTitle: String!) {
  allContentfulMenu(sort: {fields: order, order: ASC}) {
    edges {
      node {
        name
        link
      }
    }
  }
  allContentfulSiteInfo {
    nodes {
      id
      copyrightText
      addressLink
      address {
        address
      }
      domain
      email
      fullName
      logoLarge {
        gatsbyImageData(formats: WEBP)
      }
      logoSmall {
        gatsbyImageData(formats: WEBP)
      }
      phone
      shortName
    }
  }
  allContentfulSocials {
    nodes {
      link
      name
    }
  }
  allContentfulPost(filter: { postTitle: { eq: $postTitle } }) {
    nodes {
      postTitle
      category
      featuredImage {
        title
        gatsbyImageData(placeholder: DOMINANT_COLOR, formats: WEBP, layout: CONSTRAINED)
        file {
          url
        }
      }
      postImages {
        gatsbyImageData(placeholder: DOMINANT_COLOR, formats: WEBP, layout: CONSTRAINED)
      }
      postDescription {
        postDescription
        childMarkdownRemark {
          html
        }
      }
      year
    }
  }
}
`
